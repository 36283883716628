import { render, staticRenderFns } from "./Catalog.vue?vue&type=template&id=3ce53d8e&scoped=true"
import script from "./Catalog.vue?vue&type=script&lang=js"
export * from "./Catalog.vue?vue&type=script&lang=js"
import style0 from "./Catalog.vue?vue&type=style&index=0&id=3ce53d8e&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ce53d8e",
  null
  
)

export default component.exports